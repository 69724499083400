import axios from 'axios';

const baseURL =
  process.env.REACT_APP_DATA_PROVIDER_URL || 'http://localhost:1337';

const httpClient = axios.create({ baseURL });

const getToken = async () => {
  const accessToken = localStorage.getItem('access_token');
  return accessToken;
};
httpClient.interceptors.request.use(async config => {
  const accessToken = await getToken();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
});

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    const query = {
      // sort: JSON.stringify([field, order]),
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      // filter: JSON.stringify(params.filter),

      'pagination[page]': page,
      'pagination[pageSize]': perPage,
      'pagination[withCount]': true,
    };
    const url = `${resource}?${new URLSearchParams(query)}`;

    return httpClient
      .get(url)
      .then(
        ({
          headers,
          data: { data, meta: { pagination: { total } = {} } = {} },
        }) => ({
          data,
          total,
          // total: parseInt(headers.get('content-range').split('/').pop(), 10),
        })
      );
  },

  getOne: (resource, params) =>
    httpClient.get(`${resource}/${params.id}`).then(({ data }) => ({
      data: data.data || data,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${resource}?${new URLSearchParams(query)}`;
    return httpClient.get(url).then(({ data }) => ({ data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${resource}?${new URLSearchParams(query)}`;

    return httpClient.get(url).then(({ headers, data }) => ({
      data,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: (resource, params) =>
    httpClient
      .put(`${resource}/${params.id}`, params.data, {})
      .then(({ data }) => ({ data })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient
      .put(`${resource}?${new URLSearchParams(query)}`, params.data, {})
      .then(({ data }) => ({ data }));
  },

  create: (resource, params) => {
    console.log({ params });
    return httpClient.post(`${resource}`, params.data, {}).then(({ data }) => ({
      data: { ...params.data, id: data.id },
    }));
  },

  delete: (resource, params) =>
    httpClient(`${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ data }) => ({ data })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient
      .delete(`${resource}?${new URLSearchParams(query)}`, {})
      .then(({ data }) => ({ data }));
  },
};

export default dataProvider;
