import { Card, CardContent, CardHeader } from '@mui/material';
import { usePermissions } from '../App';
import Blocked from '../components/Blocked';

const Dashboard = () => {
  const { isLoading, permissions } = usePermissions();
  console.log({ isLoading, permissions });
  return (
    <Card>
      {(() => {
        if (
          !permissions?.['plugin::users-permissions']?.controllers?.user?.me
            ?.enabled
        ) {
          return <Blocked />;
        }
        return (
          <>
            <CardHeader title="Welcome to the administration" />
            <CardContent>Lorem ipsum sic dolor amet...</CardContent>
          </>
        );
      })()}
    </Card>
  );
};
export default Dashboard;
