import * as React from 'react';

import {
  useRecordContext,
  useList,
  ListContextProvider,
  SingleFieldList,
  ArrayField,
  EmailField,
  TextField,
} from 'react-admin';
import { css } from '@emotion/react';

const EmailArrayField = ({ source }) => {
  const record = useRecordContext();
  let emails = record[source];
  try {
    if (typeof emails === 'string') {
      emails = JSON.parse(emails);
    }
  } catch (error) {
    // not json
  }
  console.log({ emails });
  const data = [
    { emails: emails?.map?.(email => ({ email: email?.email || email })) },
  ];
  console.log({ data });
  const listContext = useList({
    data,
  });

  if (!record?.[source]) {
    return null;
  }

  if (!Array.isArray(emails)) {
    return <TextField source={source} />;
  }

  return (
    <ListContextProvider value={listContext}>
      <SingleFieldList
        sx={css`
          align-content: center;
          height: 2.25rem;
        `}
      >
        <ArrayField source="emails">
          <SingleFieldList>
            <EmailField
              source="email"
              sx={css`
                padding-right: 10px;
              `}
            />
          </SingleFieldList>
        </ArrayField>
      </SingleFieldList>
    </ListContextProvider>
  );
};

export default EmailArrayField;
