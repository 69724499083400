import jwtDecode from 'jwt-decode';
import dataProvider from '../data/dataProvider';

const authProvider = ({ setPermissions }) => ({
  // called when the user attempts to log in
  /**
   *
   * @param {object} credentials
   * @param {object} credentials.id_token
   * @param {object} credentials.access_token
   * @param {object} credentials.refresh_token
   */
  login: async credentials => {
    console.log({ credentials });
    const { access_token } = credentials;
    localStorage.setItem('access_token', access_token);
    console.log('login', { access_token });
    const { data: me } =
      (await dataProvider
        .getOne('api/users', { id: 'me' })
        .catch(error => console.error(error))) || {};
    console.log('login', { me });
    if (me?.role) {
      const { data: role } =
        (await dataProvider
          .getOne('api/users-permissions/roles', { id: me.role.id })
          .catch(error => console.error(error))) || {};
      console.log('login', { role });
      localStorage.setItem('role', JSON.stringify(role.role || role));
    }

    return;
  },
  // called when the user clicks on the logout button
  logout: async () => {
    console.log('logging out');
    window.localStorage.clear();

    // const query = new URLSearchParams({
    //   client_id: '44k8mnmkrpoq0d62up9dedefdc',
    //   response_type: 'code',
    //   redirect_uri: `https://api.cove${
    //     process.env.NODE_ENV !== 'production' ? 'stage' : 'smart'
    //   }.com/v4/oauth2/connect/cognito/callback`,
    //   scope: 'email openid profile admin.covesmart.com/user',
    //   state: JSON.stringify({
    //     clientId: '44k8mnmkrpoq0d62up9dedefdc',
    //     callback: `${window.location.origin}/auth/cognito/callback`,
    //     path: '/',
    //   }),
    // });

    // window.location.href = `${
    //   process.env.REACT_APP_COGNITO_AUTHORIZE ||
    //   'https://id.covesmart.com/logout'
    // }?${query}`;

    return;
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('access_token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const accessToken = localStorage.getItem('access_token');
    // console.log(accessToken);
    try {
      // const { data: me } =
      //   (await dataProvider
      //     .getOne('api/users', { id: 'me' })
      //     .catch(error => console.error(error))) || {};
      // console.log('checkAuth', { me });
      if ((jwtDecode(accessToken)?.exp || 0) * 1000 < Date.now()) {
        throw new Error('access_token expired');
      }
    } catch (error) {
      console.error(error);
      localStorage.removeItem('access_token');
      throw error;
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    console.log('getPermissions');
    let permissions;
    let role;
    try {
      role = JSON.parse(localStorage.getItem('role'));
    } catch (error) {
      console.error('getPermissions Error', error);
    }
    console.log('getPermissions role', { role });
    if (!role) {
      const query = new URLSearchParams({ populate: 'role' }).toString();
      const { data: me } =
        (await dataProvider
          .getOne('api/users', { id: `me?${query}` })
          .catch(error => console.error(error))) || {};
      console.log('getPermissions me', { me });
      if (me?.role) {
        const { data: dataRole } =
          (await dataProvider
            .getOne('api/users-permissions/roles', { id: me.role.id })
            .catch(error => console.error(error))) || {};
        console.log('getPermissions dataRole', { dataRole });
        localStorage.setItem('role', JSON.stringify(dataRole.role || dataRole));
        role = dataRole;
      }
    }
    console.log('getPermissions role', { role });
    try {
      permissions = role?.permissions;
    } catch (error) {
      console.error('getPermissions Error', error);
    }
    console.log('getPermissions permissions', { permissions });
    setPermissions(permissions);
    return permissions || {};
  },
});

export default authProvider;
