import { useState, useCallback, createContext, useContext } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Admin, Resource, CustomRoutes, defaultTheme } from 'react-admin';
import authProvider from './providers/auth/authProvider';
import dataProvider from './providers/data/dataProvider';
import Dashboard from './pages/Dashboard';
import AdminLayout from './components/AdminLayout';
import LoginRedirect from './pages/LoginRedirect';
import AuthCallback from './pages/AuthCallback';
import UserIcon from '@mui/icons-material/Group';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { UserList } from './pages/user/users';
import {
  ShortcodeList,
  ShortcodeShow,
  ShortcodeEdit,
  ShortcodeCreate,
  shortcodeListSchema,
} from './pages/shortcode/shortcodes';
import { validator } from '@exodus/schemasafe';
import { createTheme } from '@mui/material';

/**
 * @typedef {object} Permissions
 */

/**
 * @typedef {object} CheckPermissionParams
 * @property {import('@exodus/schemasafe').Schema} [schema]
 */

/**
 * @callback CheckPermission
 * @param {CheckPermissionParams} params
 * @returns {boolean}
 */

/**
 * @typedef {object} PermissionsProvider
 * @property {Permissions} permissions
 * @property {import('react').Dispatch<(prevState: Permissions) => Permissions>} setPermissions
 * @property {CheckPermission} checkPermission
 */

/**
 * @type {import('react').Context<PermissionsProvider>}
 */
const PermissionsContext = createContext();

/**
 * @param {any} props
 * @returns {import('react').Provider<PermissionsProvider>}
 */
const PermissionsProvider = props => {
  const [permissions, setPermissions] = useState();

  const permissionValidator = useCallback(
    /**
     * @param {import('@exodus/schemasafe').Schema} schema
     */
    schema => validator(schema),
    []
  );
  const checkPermission = useCallback(
    /**
     * @type {CheckPermission}
     */
    ({ schema }) => {
      try {
        if (!schema || !permissions) {
          return false;
        }
        const checkPermissionValidator = permissionValidator(schema);
        return checkPermissionValidator(permissions);
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    [permissionValidator, permissions]
  );
  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        setPermissions: newPermissions => {
          if (JSON.stringify(newPermissions) !== JSON.stringify(permissions)) {
            return setPermissions(newPermissions);
          }
          return permissions;
        },
        checkPermission,
      }}
      {...{ ...props }}
    />
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(`usePermissions must be used within a PermissionsContext`);
  }
  return context;
};

const AdminWithPermissions = () => {
  const { setPermissions, checkPermission } = usePermissions();
  return (
    <BrowserRouter>
      <Admin
        layout={AdminLayout}
        dataProvider={dataProvider}
        authProvider={authProvider({ setPermissions })}
        dashboard={Dashboard}
        loginPage={LoginRedirect}
        lightTheme={defaultTheme}
        darkTheme={createTheme({
          palette: { mode: 'dark' },
        })}
      >
        <CustomRoutes noLayout>
          <Route path="/auth/cognito/callback" element={<AuthCallback />} />
        </CustomRoutes>
        {checkPermission({
          schema: {
            type: 'object',
            required: ['plugin::users-permissions'],
            properties: {
              'plugin::users-permissions': {
                required: ['controllers'],
                properties: {
                  controllers: {
                    required: ['user'],
                    properties: {
                      user: {
                        required: ['find'],
                        properties: {
                          find: {
                            required: ['enabled'],
                            properties: {
                              enabled: {
                                type: 'boolean',
                                enum: [true],
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        }) && (
          <Resource
            name="users"
            options={{ label: 'users - UserList' }}
            list={UserList}
            icon={UserIcon}
          />
        )}
        {checkPermission({
          schema: shortcodeListSchema,
        }) && (
          <Resource
            name="api/shortcode"
            options={{ label: 'Shortcode' }}
            list={ShortcodeList}
            show={ShortcodeShow}
            edit={ShortcodeEdit}
            create={ShortcodeCreate}
            icon={QrCodeIcon}
          />
        )}
        <Resource
        // this is blank resouce to show dashboard if no other access is given
        />
      </Admin>
    </BrowserRouter>
  );
};

export const App = () => {
  return (
    <PermissionsProvider>
      <AdminWithPermissions />
    </PermissionsProvider>
  );
};

export default App;
