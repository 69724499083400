import { useState, useEffect } from 'react';
import {
  useLogin,
  useNotify,
  useAuthState,
  Error,
  Loading,
  HttpError,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';

const AuthCallback = ({ theme }) => {
  const navigate = useNavigate();
  const notify = useNotify();
  const login = useLogin();
  const { isLoading, authenticated } = useAuthState();
  const [loading, setLoading] = useState(true);
  /**
   * @type {[HttpError, import('react').Dispatch<(prevState?: HttpError) => HttpError>]}
   */
  const [error, setError] = useState();

  useEffect(() => {
    const checkToken = async () => {
      if (typeof window !== 'undefined') {
        setLoading(true);
        try {
          const searchParams = new URLSearchParams(window.location.search);
          const paramsError =
            (searchParams.has('error') && searchParams.get('error')) ||
            (!searchParams.has('id_token') && 'Missing id_token') ||
            (!searchParams.has('access_token') && 'Missing access_token') ||
            (!searchParams.has('refresh_token') && 'Missing refresh_token');
          if (paramsError) {
            const error = new HttpError(paramsError, 500, window.location);
            error.componentStack = JSON.stringify(error.body);
            setError(error);
            return;
          }

          let path;
          try {
            path =
              (searchParams.has('state') &&
                JSON.parse(searchParams.get('state'))?.path) ||
              '/';
          } catch (error) {
            // state not json oh well
          }
          await login({
            id_token: searchParams.get('id_token'),
            access_token: searchParams.get('access_token'),
            refresh_token: searchParams.get('refresh_token'),
          });
          if (path) {
            navigate(path);
          }
          notify('Succesfully Logged In', { type: 'success' });
        } finally {
          setLoading(false);
        }
      }
    };
    checkToken();
  }, [login, navigate, notify]);

  if (error) {
    return (
      <Error
        error={error}
        errorInfo={(error.componentStack && error) || null}
      />
    );
  }

  if (isLoading || loading) {
    return <Loading />;
  }

  if (!authenticated) {
    const error = new Error('Login Error');
    return (
      <Error
        error={error}
        errorInfo={(error.componentStack && error) || null}
      />
    );
  }

  return <Error error={new Error('Login Error')} />;
};

export default AuthCallback;
