import { useState, useCallback, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import {
  useRecordContext,
  List,
  Datagrid,
  DateField,
  TextField,
  ResourceContext,
  ShowButton,
  Show,
  SimpleShowLayout,
  Button,
  useDataProvider,
  Edit,
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  SelectInput,
  DateTimeInput,
  required,
} from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import EmailArrayField from '../../components/EmailArrayField';
import { css } from '@emotion/react';

const useResource = () => {
  const context = useContext(ResourceContext);
  if (!context) {
    throw new Error(`useResource must be used within a ResourceContext`);
  }
  return context;
};

/**
 * @param {string} pdf
 */
const showFile = ({ pdf, filename }) => {
  const data = `data:application/pdf;base64,${pdf}`;

  var link = document.createElement('a');
  link.href = data;
  // link.target = '_blank';
  link.download = filename;
  link.click();
};

const DownloadField = ({ source }) => {
  const resource = useResource();
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  console.log({ resource });
  console.log({ record });
  const downloadPdf = useCallback(
    async e => {
      setLoading(true);
      try {
        const { data } = await dataProvider.getOne(resource, {
          id: `${record.id}/download-pdf`,
        });
        console.log({ data });
        showFile({ filename: `${data.id}.pdf`, pdf: data.pdf });
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [dataProvider, setLoading, record, resource]
  );

  if (!record) return null;
  return (
    <Button disabled={loading} onClick={downloadPdf}>
      {loading ? (
        <>
          Downloading
          <CircularProgress
            size={12}
            sx={css`
              padding-left: 5px;
              margin-left: 5px;
            `}
          />
        </>
      ) : (
        'Download PDF'
      )}
    </Button>
  );
};

export const ShortcodeList = props => {
  console.log({ props });
  return (
    <List>
      <Datagrid>
        <TextField source="id" />
        <TextField source="output" />
        <TextField source="version" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <TextField source="shortcode_count" />
        <TextField source="upc" />
        <TextField source="retailer" />
        <EmailArrayField source="to" />
        <DownloadField source="output" />
        <ShowButton label="View" />
      </Datagrid>
    </List>
  );
};

export const ShortcodeShow = props => {
  console.log({ props });
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="output" />
        <TextField source="version" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <TextField source="shortcode_count" />
        <TextField source="upc" />
        <TextField source="retailer" />
        <EmailArrayField source="to" />
        <DownloadField source="output" />
      </SimpleShowLayout>
    </Show>
  );
};

export const shortcodeListSchema = {
  type: 'object',
  required: ['api::shortcode'],
  properties: {
    'api::shortcode': {
      required: ['controllers'],
      properties: {
        controllers: {
          required: ['shortcode'],
          properties: {
            user: {
              oneOf: [
                {
                  required: ['findMany'],
                  properties: {
                    findMany: {
                      required: ['enabled'],
                      properties: {
                        enabled: {
                          type: 'boolean',
                          enum: [true],
                        },
                      },
                    },
                  },
                },
                {
                  required: ['findOne'],
                  properties: {
                    findOne: {
                      required: ['enabled'],
                      properties: {
                        enabled: {
                          type: 'boolean',
                          enum: [true],
                        },
                      },
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
};

export const ShortcodeCreate = props => {
  console.log({ props });
  const id = uuid();
  const at = Date.now();
  const output = `${id}/shortcodes.${at}.pdf`;
  return (
    <Create>
      <SimpleForm>
        <TextInput source="id" fullWidth disabled defaultValue={id} />
        <TextInput source="output" fullWidth disabled defaultValue={output} />
        <NumberInput
          source="shortcode_count"
          min={1}
          step={1}
          validate={[required()]}
          defaultValue={1}
        />
        <SelectInput
          source="version"
          fullWidth
          emptyValue={''}
          validate={[required()]}
          choices={[
            {
              id: 'Avery_5160_label_template.side_by_side',
              name: '10 count - sideBySide - Avery_5160_label_template.side_by_side',
            },
            {
              id: 'Avery_5160_label_template',
              name: '30 count - Avery_5160_label_template',
            },
            {
              id: 'Avery_5162_label_template',
              name: '7 count - sideBySide - Avery_5162_label_template',
            },
            {
              id: 'CO_RetailPrint_vo7_8x11_half.pdf',
              name: '2 count - CO_RetailPrint_vo7_8x11_half.pdf',
            },
          ]}
        />
        <TextInput source="upc" />
        <TextInput source="retailer" />
        {/* <ArrayInput source="to">
          <SimpleFormIterator>
            <TextInput label="Email Address" source="email" type="email" />
          </SimpleFormIterator>
        </ArrayInput> */}
      </SimpleForm>
    </Create>
  );
};

export const ShortcodeEdit = props => {
  console.log({ props });
  return (
    <Edit>
      <SimpleForm defaultValues={{ shortcode_count: 1 }}>
        <TextInput source="id" fullWidth disabled />
        <TextInput source="output" fullWidth disabled />
        <TextInput
          source="shortcode_count"
          min={1}
          step={1}
          validate={[required()]}
        />
        <SelectInput
          source="version"
          fullWidth
          emptyValue={null}
          validate={[required()]}
          choices={[
            {
              id: 'Avery_5160_label_template.side_by_side',
              name: '10 count - sideBySide - Avery_5160_label_template.side_by_side',
            },
            {
              id: 'Avery_5160_label_template',
              name: '30 count - Avery_5160_label_template',
            },
            {
              id: 'Avery_5162_label_template',
              name: '7 count - sideBySide - Avery_5162_label_template',
            },
            {
              id: 'CO_RetailPrint_vo7_8x11_half.pdf',
              name: '2 count - CO_RetailPrint_vo7_8x11_half.pdf',
            },
          ]}
        />
        <TextInput source="upc" />
        <TextInput source="retailer" />
        <TextInput source="to" fullWidth disabled />
        <DateTimeInput source="created_at" disabled />
        <DateTimeInput source="updated_at" disabled />
      </SimpleForm>
    </Edit>
  );
};
