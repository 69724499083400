import { useState, useEffect } from 'react';
import { useAuthState, Error, Loading } from 'react-admin';

const LoginRedirect = ({ theme }) => {
  const { isLoading, authenticated } = useAuthState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        setLoading(true);
        const query = new URLSearchParams({
          client_id: '44k8mnmkrpoq0d62up9dedefdc',
          response_type: 'code',
          redirect_uri: `https://api.cove${
            process.env.NODE_ENV !== 'production' ? 'stage' : 'smart'
          }.com/v4/oauth2/connect/cognito/callback`,
          scope: 'email openid profile admin.covesmart.com/user',
          state: JSON.stringify({
            clientId: '44k8mnmkrpoq0d62up9dedefdc',
            callback: `${window.location.origin}/auth/cognito/callback`,
            path: '/',
          }),
        });

        window.location.href = `${
          process.env.REACT_APP_COGNITO_AUTHORIZE ||
          'https://id.covesmart.com/oauth2/authorize'
        }?${query}`;
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }, [setLoading]);

  if (isLoading || loading) {
    return <Loading />;
  }

  if (!authenticated) {
    const error = new Error('Login Error');
    return (
      <Error
        error={error}
        errorInfo={(error.componentStack && error) || null}
      />
    );
  }

  return <Loading />;
};

export default LoginRedirect;
