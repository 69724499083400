import { CardContent, CardHeader } from '@mui/material';

const Blocked = () => (
  <>
    <CardHeader title="You no longer have access to this page."></CardHeader>
    <CardContent>
      If you feel there has been a mistake, please contact your workplace
      administrator.
    </CardContent>
  </>
);
export default Blocked;
